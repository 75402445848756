import React, { createContext, useReducer } from 'react'
import { getCompleteToken, setCompleteToken } from '@cuidardigital/commons/services/auth'
import { setStorage } from '@cuidardigital/commons/functions/storage'
import IDoctor, { initial as DoctorInitial } from '@cuidardigital/commons/types/IDoctor'
import IPatient, { initial as PatientInitial } from '@cuidardigital/commons/types/IPatient'
import ISession, { initial as SessionInitial } from '@cuidardigital/commons/types/ISession'

import base64 from 'base-64'
import utf8 from 'utf8'

const TYPES = {
	SET_PATIENT: 'SET_PATIENT',
	// SET_DOCTOR_INFO: 'SET_DOCTOR_INFO',
	SET_DOCTOR_LIST: 'SET_DOCTOR_LIST',
	SET_USER_SESSION: 'SET_USER_SESSION',
	SET_DOCTOR_CURRENT: 'SET_DOCTOR_CURRENT',
	MESSAGE_ERROR: 'MESSAGE_ERROR'
}

interface IObjectLiteral {
	[key: string]: any
}

interface IState {
	state: IObjectLiteral
	session: ISession
	doctorList: IDoctor[]
	doctorCurrent: IDoctor
	patient: IPatient
	messageError: string
	// doctorInfo: IObjectLiteral
	dispatch?: any
}

interface IPayload {
	type: string
	payload: any
}

// @ts-ignore
const initialState: IState = {
	session: SessionInitial,
	doctorList: [],
	// doctorInfo: {}
	doctorCurrent: DoctorInitial,
	patient: PatientInitial,
	messageError: ''
}
const store = createContext(initialState)
const { Provider } = store

interface IProps {
	children?: any
}

const updateLocalStorage = (field: string, payload: any) => {
	const bytes = base64.decode(getCompleteToken())
	// userStorage: { token, user, doctor}
	const data = JSON.parse(utf8.decode(bytes))
	const newData = { ...data[field], ...payload }

	const serialized = JSON.stringify({ token: data.accessToken, [field]: newData })
	setStorage(serialized)
	const bytesEncoded = utf8.encode(JSON.stringify({ ...data, [field]: newData }))
	setCompleteToken(base64.encode(bytesEncoded))
	return [newData]
}

const StateProvider = ({ children }: IProps) => {
	const [state, dispatch] = useReducer((prevState: IState, { type, payload }: IPayload) => {
		switch (type) {
			case TYPES.SET_USER_SESSION:
				const [newUser] = updateLocalStorage('user', payload)
				return { ...prevState, session: { ...newUser, isDoctor: newUser.role === 'doctor' } }
			case TYPES.SET_DOCTOR_LIST:
				const dc = payload[0]
				return { ...prevState, doctorList: payload, doctorCurrent: { ...dc, cpf: dc.username } }
			case TYPES.SET_DOCTOR_CURRENT:
				const [newDoctor] = updateLocalStorage('doctorCurrent', payload)
				return { ...prevState, doctorCurrent: newDoctor }
			// case TYPES.SET_DOCTOR_INFO:
			// 	return { ...prevState, doctorInfo: payload }
			case TYPES.SET_PATIENT:
				return { ...prevState, patient: payload }
			default:
				throw new Error()
		}
	}, initialState)

	// @ts-ignore
	return <Provider value={{ state, dispatch }}>{children}</Provider>
}

export { store, StateProvider, TYPES }
