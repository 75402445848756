export default interface ISession {
	accessToken: string
	username: string
	role: string
	cpf: string
	email: string
	name: string
	status: string | number
}

export const initial: ISession = { accessToken: '', username: '', role: '', cpf: '', email: '', name: '', status: '' }
