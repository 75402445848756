// import callApiToken from './validate-token'

export const TOKEN_KEY = 'Fleury-Token'
export const USER_LOGIN = 'Fleury-Email'
export const USER_PASSWORD = 'Fleury-Password'
export const USER = 'Fleury-User'
export const TOKEN_KEY_PASSWORD = 'Fleury-Token-Password'
export const USER_NAME = 'Fleury-UserName-Password'
export const REGISTRING = 'Fleury-Registring-Password'
export const USER_EMAIL = 'Fleury-UserEmail-Password'
export const ASSISTANT_CREATE_TOKEN = 'Fleury-Token-Assistant-Create'
export const ASSISTANT_CREATE_NAME = 'Fleury-Name-Assistant-Create'
export const ASSISTANT_CREATE_EMAIL = 'Fleury-Email-Assistant-Create'
export const ASSISTANT_CREATE_CPF = 'Fleury-Cpf-Assistant-Create'
export const ASSISTANT_CREATE_DOCTOR_NAME = 'Fleury-DoctorName-Assistant-Create'
export const COMPLETE_TOKEN = 'Fleury-complete-token'
export const PATIENT_PRE_CONSULTING = 'Fleury-patient-pre-consulting'
export const PATIENT_CHECKOUT = 'Fleury-patient-checkout'

const isWindow = typeof window !== 'undefined'
export const setCompleteToken = (token: string | string[]) =>
	isWindow && window.localStorage.setItem(COMPLETE_TOKEN, token)
export const getCompleteToken = () => {
	if (isWindow) {
		const token = window.localStorage.getItem(COMPLETE_TOKEN)
		if (token) return token
		logout()
	}
}

// Token
export const isAuthenticated = async () => {
	if (isWindow && !window.localStorage.getItem(TOKEN_KEY)) return false
	return true
}

export const getToken = () => isWindow && window.localStorage.getItem(TOKEN_KEY)

export const getUser = () => JSON.parse((isWindow && window.localStorage.getItem(USER)) || '') || {}

export const getTokenPassword = () => isWindow && window.localStorage.getItem(TOKEN_KEY_PASSWORD)

export const getUserName = () => isWindow && window.localStorage.getItem(USER_NAME)

export const getRegistring = () => isWindow && window.localStorage.getItem(REGISTRING)

export const getUserEmail = () => isWindow && window.localStorage.getItem(USER_EMAIL)

export const resetPassword = ({ accessToken, username, firstAccess }: any) => {
	isWindow && window.localStorage.setItem(TOKEN_KEY_PASSWORD, accessToken)
	isWindow && window.localStorage.setItem(USER_NAME, username)
	isWindow && window.localStorage.setItem(REGISTRING, firstAccess)
}

export const newPatient = ({ token, name, email }: any) => {
	isWindow && window.localStorage.setItem(TOKEN_KEY_PASSWORD, token)
	isWindow && window.localStorage.setItem(USER_NAME, name)
	isWindow && window.localStorage.setItem(USER_EMAIL, email)
}

export const deleteNewPatient = () => {
	isWindow && window.localStorage.removeItem(TOKEN_KEY_PASSWORD)
	isWindow && window.localStorage.removeItem(USER_NAME)
	isWindow && window.localStorage.removeItem(USER_EMAIL)
}

export const deteleResetPassword = () => {
	isWindow && window.localStorage.removeItem(TOKEN_KEY_PASSWORD)
	isWindow && window.localStorage.removeItem(USER_NAME)
}

export const login = ({ accessToken, user }: any) => {
	isWindow && window.localStorage.setItem(TOKEN_KEY, accessToken)
	isWindow && window.localStorage.setItem(USER, JSON.stringify(user))
}

export const logout = () => {
	// w && window.localStorage.removeItem(TOKEN_KEY)
	isWindow && window.localStorage.clear()
	isWindow &&
		window.open(process.env.GATSBY_LANDING_URL || 'http://dsxagaw9xx5qx.cloudfront.net', '_self')
}

// Credentials (email, password)
export const rememberCredentials = (credentials: any) => {
	isWindow && window.localStorage.setItem(USER_LOGIN, credentials.login)
	isWindow && window.localStorage.setItem(USER_PASSWORD, credentials.password)
}

export const getCredentials = () => ({
	login: isWindow && window.localStorage.getItem(USER_LOGIN),
	password: isWindow && window.localStorage.getItem(USER_PASSWORD)
})

export const clearCredentials = () => {
	isWindow && window.localStorage.removeItem(USER_LOGIN)
	isWindow && window.localStorage.removeItem(USER_PASSWORD)
}

export const assistantCreate = ({ token, name, email, cpf, doctorName }: any) => {
	isWindow && window.localStorage.setItem(ASSISTANT_CREATE_TOKEN, token)
	isWindow && window.localStorage.setItem(ASSISTANT_CREATE_NAME, name)
	isWindow && window.localStorage.setItem(ASSISTANT_CREATE_EMAIL, email)
	isWindow && window.localStorage.setItem(ASSISTANT_CREATE_CPF, cpf)
	isWindow && window.localStorage.setItem(ASSISTANT_CREATE_DOCTOR_NAME, doctorName)
}

export const deleteAssistantCreate = () => {
	isWindow && window.localStorage.removeItem(ASSISTANT_CREATE_TOKEN)
	isWindow && window.localStorage.removeItem(ASSISTANT_CREATE_NAME)
	isWindow && window.localStorage.removeItem(ASSISTANT_CREATE_EMAIL)
	isWindow && window.localStorage.removeItem(ASSISTANT_CREATE_CPF)
	isWindow && window.localStorage.removeItem(ASSISTANT_CREATE_DOCTOR_NAME)
}

export const getAssistantCreateToken = () =>
	isWindow && window.localStorage.getItem(ASSISTANT_CREATE_TOKEN)
export const getAssistantCreateName = () =>
	isWindow && window.localStorage.getItem(ASSISTANT_CREATE_NAME)
export const getAssistantCreateEmail = () =>
	isWindow && window.localStorage.getItem(ASSISTANT_CREATE_EMAIL)
export const getAssistantCreateCpf = () =>
	isWindow && window.localStorage.getItem(ASSISTANT_CREATE_CPF)
export const getAssistantCreateDoctorName = () =>
	isWindow && window.localStorage.getItem(ASSISTANT_CREATE_DOCTOR_NAME)

export const patientPreConsulting = (values: any) => {
	isWindow && window.localStorage.setItem(PATIENT_PRE_CONSULTING, JSON.stringify(values))
}

export const deletePatientPreConsulting = () => {
	isWindow && window.localStorage.removeItem(PATIENT_PRE_CONSULTING)
}

export const getPatientPreConsulting = () => {
	const values = isWindow && window.localStorage.getItem(PATIENT_PRE_CONSULTING)
	return values && JSON.parse(values)
}

export const storagePatientCheckout = (checkout: any) => {
	isWindow && window.localStorage.setItem(PATIENT_CHECKOUT, JSON.stringify(checkout))
}

export const getPatientCheckout = () => {
	try {
		const checkout = isWindow && window.localStorage.getItem(PATIENT_CHECKOUT)
		if (checkout) return JSON.parse(checkout)
	} catch {}
	return {}
}
