export default interface IDoctor {
	name: string
	email: string
	phone: string
	crm: string
	birthDay: string
	cpf: string
	status: string
	cnpjs?: string[]
	address?: any
	paymentId?: string
	paymentStatus?: string
}
export const initial: IDoctor = {
	name: '',
	email: '',
	phone: '',
	birthDay: '',
	cpf: '',
	crm: '',
	status: '',
	cnpjs: [],
	address: {},
	paymentId: '',
	paymentStatus: ''
}
