module.exports = [{
      plugin: require('../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"useMozJpeg":false,"stripMetadata":true,"defaultQuality":75,"icon":"src/logo.svg","name":"Saúde Digital","start_url":"/","display":"minimal-ui"},
    },{
      plugin: require('../../../node_modules/@sentry/gatsby/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
