const theme = {
	// breakpoints = ["576px", "768px", "992px", "1200px", "1800px"],
	container: '1366px',
	colors: {
		primary: '#0b67bd',
		primary_hover: '#0E81ED',
		primary_border: '#2365A7',
		secundary: '#F4F4F6',
		secundary_hover: '#E8E9EC',
		secundary_border: '#D2D4DA',
		menu_button_bg: '#F7F7F7',
		menu_button_bg_hover: '#F7F7F799',
		red_alert: '#CC092F',
		red_alert_hover: '#E60935',
		red_alert_border: '#99000F',
		button_disabled: '#E8E9EC',
		button_disabled_border: '#D5D7DD',
		font: '#ffffff',
		font_secundary: '#0B67BE',
		font_disabled: '#A5AAB6',
		transparent: 'transparent',
		transparent_hover: '#FFFFFF',
		font_highlight: '#0B67BD',
		font_dark: '#4D4E53',
		bg_not_signed: '#FFE2A5',
		bg_signed: '#29B67B34',
		font_not_signed: '#B57B00',
		font_signed: '#29B67B',
		shine_line: '#F0F4F5',
		orange: '#F2672C',
		yellow: '#FDB724',
		darkGray: '#6C6C6C',
		gray: '#748A96',
		green: '#29B67B',
		gray10: '#f7f7f7',
		white: '#ffffff',
		black: '#000000',
		blueFullish: 'rgba(39, 96, 227, 0.2)',
		blue: '#2760E3',
		blue_hover: '#17509E',
		gray60: '#999999',
		ice: '#F0F4F5',
		gray80: '#535353',
		alert: '#ff0000',
		alert_hover: '#9E1D20',
		darkRed: '#9E1D20'
	}
}

export default theme
