import React from 'react'
import { ThemeProvider, createGlobalStyle } from 'styled-components'
import { datadogLogs } from '@datadog/browser-logs'
import ReactGA from 'react-ga'
import { StateProvider } from './src/stores/app'
import theme from './theme'
import 'semantic-ui-css/semantic.min.css'
// import 'react-toastify/dist/ReactToastify.min.css'
import './src/fonts/css/bradesco-sans.css'
import './src/assets/styles.css'
import 'shepherd.js/dist/css/shepherd.css'
import './src/assets/tourStyles.css'

ReactGA.initialize(process.env.GATSBY_ANALYTICS_ID)
datadogLogs.init({
	clientToken: process.env.GATSBY_DATADOG_TOKEN,
	site: 'datadoghq.com',
	forwardErrorsToLogs: true,
	sampleRate: 100
})

const GlobalStyle = createGlobalStyle`
	body {
		margin: 0;
	}
`

export const wrapRootElement = ({ element }) => (
	<StateProvider>
		<ThemeProvider theme={theme}>
			<>
				<GlobalStyle />
				{element}
			</>
		</ThemeProvider>
	</StateProvider>
)
