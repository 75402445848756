// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-app-assistant-index-tsx": () => import("./../../../src/pages/app/assistant/index.tsx" /* webpackChunkName: "component---src-pages-app-assistant-index-tsx" */),
  "component---src-pages-app-financial-index-tsx": () => import("./../../../src/pages/app/financial/index.tsx" /* webpackChunkName: "component---src-pages-app-financial-index-tsx" */),
  "component---src-pages-app-financial-register-index-tsx": () => import("./../../../src/pages/app/financial/register/index.tsx" /* webpackChunkName: "component---src-pages-app-financial-register-index-tsx" */),
  "component---src-pages-app-index-tsx": () => import("./../../../src/pages/app/index.tsx" /* webpackChunkName: "component---src-pages-app-index-tsx" */),
  "component---src-pages-app-patient-detail-index-tsx": () => import("./../../../src/pages/app/patientDetail/index.tsx" /* webpackChunkName: "component---src-pages-app-patient-detail-index-tsx" */),
  "component---src-pages-app-tour-index-tsx": () => import("./../../../src/pages/app/tour/index.tsx" /* webpackChunkName: "component---src-pages-app-tour-index-tsx" */),
  "component---src-pages-app-tour-patient-detail-index-tsx": () => import("./../../../src/pages/app/tour/patientDetail/index.tsx" /* webpackChunkName: "component---src-pages-app-tour-patient-detail-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-login-index-tsx": () => import("./../../../src/pages/login/index.tsx" /* webpackChunkName: "component---src-pages-login-index-tsx" */),
  "component---src-pages-new-assistant-index-tsx": () => import("./../../../src/pages/newAssistant/index.tsx" /* webpackChunkName: "component---src-pages-new-assistant-index-tsx" */),
  "component---src-pages-new-password-index-tsx": () => import("./../../../src/pages/newPassword/index.tsx" /* webpackChunkName: "component---src-pages-new-password-index-tsx" */),
  "component---src-pages-new-patient-index-tsx": () => import("./../../../src/pages/newPatient/index.tsx" /* webpackChunkName: "component---src-pages-new-patient-index-tsx" */),
  "component---src-pages-patient-cancel-sms-index-tsx": () => import("./../../../src/pages/patientCancelSms/index.tsx" /* webpackChunkName: "component---src-pages-patient-cancel-sms-index-tsx" */)
}

