export default interface Ipatient {
	cpf: string
	name: string
	email: string
	phone: string
	birthDay: string
	doctorEmail: string
	link: string
	newConsultation: boolean
}
export const initial: Ipatient = {
	name: '',
	email: '',
	phone: '',
	birthDay: '',
	cpf: '',
	doctorEmail: '',
	link: '',
	newConsultation: true
}
