const isWindow = typeof window !== 'undefined'
export const setStorage = (value: any) => localStorage.setItem('user', value)

export const deleteStorageItem = async (type: string) => localStorage.removeItem(type)

export const deleteCache = async () => isWindow && window.localStorage.clear()

export const getUserStorage = () => {
	// @ts-ignore
	const user = JSON.parse(isWindow && window.localStorage.getItem('user'))
	return user
}
